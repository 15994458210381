export default function (statusCode) {
  switch (statusCode) {
  case 0: return 'Geplant'
  case 1: return 'Ausstehend'
  case 2: return 'In Prüfung' // deprecated
  case 3: return 'Abgeschlossen'
  case 4: return 'Abgelehnt'
  case 5: return 'Abgelaufen'
  default:
    return ''
  }
}
