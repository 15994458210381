<template>
  <div>
    <ca-header heading="GVC's" />
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="order-gvc-list"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'OrderDetail', params: {orderId: item.order._id}})"
      >
        <template slot="header-button">
          <SignedDownloadLink
            v-if="client && client.gvcFile"
            :file="client.gvcFile"
          >
            <template #default="{link}">
              <div>
                <a
                  :download="client.gvcFile.name"
                  :href="link"
                  title="GVC herunterladen"
                  class="btn btn-primary btn-sm float-sm-right"
                >
                  GVC Formular <i class="fa fa-download" />
                </a>
              </div>
            </template>
          </SignedDownloadLink>
        </template>
        <template
          slot="status"
          slot-scope="{value: status, unformatted: statusCode}"
        >
          <span
            class="badge text-white"
            :class="getStatusClass(statusCode)"
          >{{ status }}</span>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import formatGvcStatus from '../../filters/formatGvcStatus'
import SignedDownloadLink from '@/components/SignedDownloadLink'
export default {
  name: 'OrderGvcList',
  components: {
    DataTable,
    CaHeader,
    SignedDownloadLink
  },
  data: () => ({
    client: null
  }),
  computed: {
    user () {
      return this.$store.getters['auth/user'].user
    },
    fields () {
      const fields = [
        {
          label: 'Garantie/Anfrage',
          key: 'hnWarrantynumber'
        },
        {
          label: 'Erstellt am',
          key: 'order.createdAt',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          filter: {
            type: 'DATERANGE'
          }
        },
        {
          label: 'Produkt',
          key: 'order.product.rate.name'
        },
        {
          label: 'Halter',
          key: 'order.owner',
          formatter: (owner) => `${owner.firstname} ${owner.lastname}`
        }
      ]
      if (!this.isOverview && this.user.role === 10) {
        fields.push({
          label: 'Händler',
          key: 'order.user.company'
        })
      }
      fields.push(
        {
          label: 'Kennzeichen',
          key: 'order.car.licensePlate'
        },
        {
          label: 'Zeitraum',
          key: 'date',
          formatter: (date) => `${formatDate(date.start)} - ${formatDate(date.end)}`
        },
        {
          label: 'Status',
          key: 'status',
          formatter: statusCode => formatGvcStatus(statusCode),
          filter: {
            type: 'LIST',
            defaultValue: [{ value: 1 }],
            fetchOptions: () => ([
              { value: 0 },
              { value: 1 },
              { value: 2 },
              { value: 3 },
              { value: 4 },
              { value: 5 }
            ])
          }
        }
      )
      return fields
    },
    baseQuery () {
      const baseQuery = {}
      if (this.$route.meta.isOverview) {
        let userId
        const userIdFromParams = this.$route.params.userId
        if (userIdFromParams === 'me') {
          userId = this.$store.getters['auth/user'].user._id
        } else {
          userId = userIdFromParams
        }

        baseQuery['order.userId'] = userId
      } else {
        baseQuery.$client = {
          $showAll: true
        }
      }
      return baseQuery
    },
    isOverview () {
      return this.$route.meta.isOverview
    }
  },
  async created () {
    const clientReq = await this.$store.dispatch('clients/find', {
      query: {
        $limit: 1
      }
    })
    this.client = clientReq.data[0]
  },
  methods: {
    getStatusClass (statusCode) {
      let statusClass = ''
      switch (statusCode) {
      case 0: statusClass = 'badge-primary'; break
      case 1: statusClass = 'badge-success'; break
      case 2: statusClass = 'badge-primary'; break
      case 3: statusClass = 'badge-success'; break
      case 4: statusClass = 'badge-warning'; break
      case 5: statusClass = 'badge-warning'; break
      }
      return statusClass
    }
  }
}
</script>

<style scoped lang="scss">

</style>
